import { Stack } from "@mui/material";
import { Card, CardContent, Slide, Table, TableBody, TableFooter, TableHead } from "@mui/material";
import {
  AutoEllipsis,
  BodyCell,
  BodyRow,
  CopyTextButton,
  HeadCell,
  HeadRow,
  IFee,
  IRevenue,
  StickyTableContainer,
  formatDate,
  formatDollarAmount,
  formatHMS,
  getBitcoinFromSats,
  useWindowSize,
} from "@synota-io/synota-shared-ui";
import { ReactNode } from "react";

export type Fields = keyof IRevenue | keyof IFee;

export const MINER_LABELS = {
  revenueAmount: "Amount (BTC)",
  feeAmount: "Fee (BTC)",
  txid: "Transaction Id",
  paymentHash: "Payment Hash",
  txidOrPaymentHash: "Transaction / Payment Hash",
  blockHeight: "Block Height",
  time: "Date and Time (UTC)",
  revenueType: "Source",
  feeType: "Type",
  usdAmount: "Amount (USD)",
  status: "Status",
} satisfies Record<Fields, string>;

export function getLabel(key: keyof typeof MINER_LABELS): string {
  return MINER_LABELS[key];
}

interface TableField {
  key: keyof typeof MINER_LABELS;
  value: (pr: any) => ReactNode;
}

interface Props {
  fields: Array<keyof typeof MINER_LABELS>;
  items: IRevenue[] | IFee[] | null | undefined;
  isLoading: boolean;
  noDataText?: string;
}

export const FeesAndRevenueTable = ({
  items,
  fields,
  isLoading,
  noDataText = "No data found.",
}: Props) => {
  // Re-renders ellipsis calculation on window resize
  useWindowSize();

  const tableFields: TableField[] = [
    {
      value: (item) => (
        <AutoEllipsis>{`${formatDate(item.time)} ${formatHMS(item.time)}`}</AutoEllipsis>
      ),
      key: "time",
    },
    {
      value: (item) => <AutoEllipsis>{item.feeType}</AutoEllipsis>,
      key: "feeType",
    },
    {
      value: (item) => <AutoEllipsis>{item.revenueType}</AutoEllipsis>,
      key: "revenueType",
    },
    {
      value: (item) => getBitcoinFromSats(item.revenueAmount),
      key: "revenueAmount",
    },
    {
      value: (item) => getBitcoinFromSats(item.feeAmount),
      key: "feeAmount",
    },
    {
      value: (item) => {
        const hash = item.txid ? item.txid : item.paymentHash;
        return (
          <Stack alignItems="center" mr={5} direction="row">
            <AutoEllipsis>{hash}</AutoEllipsis>
            <CopyTextButton title={item.txid ? "Copy TXID" : "Copy Payment Hash"} text={hash} />
          </Stack>
        );
      },
      key: "txidOrPaymentHash",
    },
    {
      value: (item) => (item.usdAmount >= 0 ? formatDollarAmount(item.usdAmount) : null),
      key: "usdAmount",
    },
    {
      value: (item) => item.status,
      key: "status",
    },
  ];

  const visibleFields = tableFields.filter((field) => fields.includes(field.key));

  return (
    <Slide in direction="up">
      <Card>
        <CardContent>
          <StickyTableContainer>
            <Table stickyHeader>
              <TableHead>
                <HeadRow>
                  {visibleFields.map((field) => (
                    <HeadCell key={field.key}>
                      <AutoEllipsis>{MINER_LABELS[field.key]}</AutoEllipsis>
                    </HeadCell>
                  ))}
                </HeadRow>
              </TableHead>
              <TableBody>
                {items &&
                  items.map((item) => {
                    return (
                      <BodyRow key={item.time}>
                        {visibleFields.map((field) => (
                          <BodyCell key={item.time + field.key}>{field.value(item)}</BodyCell>
                        ))}
                      </BodyRow>
                    );
                  })}
              </TableBody>

              {!isLoading && !items?.length ? (
                <TableFooter>
                  <BodyRow>
                    <BodyCell>{noDataText}</BodyCell>
                  </BodyRow>
                </TableFooter>
              ) : null}
            </Table>
          </StickyTableContainer>
        </CardContent>
      </Card>
    </Slide>
  );
};
