import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import {
  ButtonRow,
  Form,
  FormButton,
  FormStep,
  FormSubmit,
  InputField,
  Modal,
  UseModalProps,
  setControlFieldErrors,
  useAccount,
  useBluePenguin,
  useCurrencyConverter,
  useToast,
} from "@synota-io/synota-shared-ui";
import { useForm } from "react-hook-form";
import * as yup from "yup";

const ERR_ROUTING_MSG = "Invalid routing number";
const ERR_ACCOUNT_MSG = "Invalid account number";

const schema = yup.object({
  routing_number: yup.string().required("Routing number is required!").typeError(ERR_ROUTING_MSG),
  account_number: yup.string().required("Account number is required!").typeError(ERR_ACCOUNT_MSG),
  account_number2: yup
    .string()
    .required("Account number is required!")
    .typeError(ERR_ACCOUNT_MSG)
    .oneOf([yup.ref("account_number")], "Account numbers must match"),
});

export const BluePenguinAchFormModal = ({ open, onClose }: UseModalProps) => {
  const { jwt, isAdmin } = useAccount();
  const { configurations, refetch } = useCurrencyConverter();
  const toast = useToast();
  const { setAchInfo, isLoading } = useBluePenguin();
  const { handleSubmit, control } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: "all",
    mode: "all",
    defaultValues: {
      account_number: "",
      account_number2: "",
      routing_number: "",
    },
  });

  if (!isAdmin) {
    return;
  }

  const onSubmit = handleSubmit(({ account_number, routing_number }) => {
    setAchInfo(
      {
        jwt,
        account_number,
        routing_number,
      },
      {
        onSuccess: ({ data }) => {
          if (data.success) {
            refetch();
            toast.success("Successfully updated ACH information");
            return onClose();
          }

          setControlFieldErrors(control, data.field_errors);
        },
      },
    );
  });

  return (
    <Modal
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      title="Set ACH Information"
      actions={
        <ButtonRow width="100%">
          <FormButton
            variant="text"
            color="error"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </FormButton>
          <Box flexGrow={1} display="flex">
            <FormSubmit isLoading={isLoading} form="bluepenguin-set-ach-info-form" fullWidth>
              Submit
            </FormSubmit>
          </Box>
        </ButtonRow>
      }
    >
      <Form id="bluepenguin-set-ach-info-form" onSubmit={onSubmit}>
        <FormStep>
          <InputField
            disabled={isLoading}
            placeholder={configurations?.BluePenguinConfiguration?.ach_routing_number}
            header="Routing Number"
            name="routing_number"
            control={control}
            fullWidth
          />
          <InputField
            disabled={isLoading}
            placeholder={configurations?.BluePenguinConfiguration?.ach_account_number}
            header="Account Number"
            name="account_number"
            control={control}
            fullWidth
          />
          <InputField
            disabled={isLoading}
            placeholder={configurations?.BluePenguinConfiguration?.ach_account_number}
            header="Repeat Account Number"
            name="account_number2"
            control={control}
            fullWidth
          />
        </FormStep>
      </Form>
    </Modal>
  );
};
