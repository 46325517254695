import { AccountProvider, useToast } from "@synota-io/synota-shared-ui";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { PropsWithChildren } from "react";
import { API_TIMEOUT_MS, SYNOTA_API_URL } from "./utils/environment";
import * as Sentry from "@sentry/react";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH, LOGIN_PATH } from "./paths";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30 * 1000,
      keepPreviousData: true,
    },
    mutations: {},
  },
});

export const ApiProvider = ({ children }: PropsWithChildren) => {
  const toast = useToast();
  const navigate = useNavigate();
  return (
    <QueryClientProvider client={queryClient}>
      <AccountProvider
        clientMaxTimeout={API_TIMEOUT_MS}
        hostURL={SYNOTA_API_URL}
        onError={(err) => {
          Sentry.captureException(err);
          navigate("/internal-server-error");
        }}
        onLogout={() => navigate(LOGIN_PATH)}
        onLogin={() => navigate(DASHBOARD_PATH)}
        onAccountSwitch={(email, description) => {
          toast.info(`Now viewing as ${description || email}`);
        }}
      >
        {children}
      </AccountProvider>
    </QueryClientProvider>
  );
};
