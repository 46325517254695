import { Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { DASHBOARD_PATH } from "../../../paths";
import { Email } from "../steps/Email";
import { Password } from "../steps/Password";
import * as yup from "yup";
import {
  Form,
  UseFormWizardStep,
  useAccount,
  useFormWizard,
  useLogin,
} from "@synota-io/synota-shared-ui";

const schema = yup
  .object({
    email: yup.string().required("Email is required"),
    password: yup.string().required("Password is required"),
  })
  .required();

const steps: UseFormWizardStep[] = [
  [Email, "email"],
  [(props) => <Password submitLabel="Login" {...props} />, "password"],
];

export const LoginPage = () => {
  const navigate = useNavigate();
  const { login, isLoading } = useLogin();
  const [pageError, setPageError] = useState<null | Error>(null);

  const { isLogged } = useAccount();
  useEffect(() => {
    if (isLogged) {
      navigate(DASHBOARD_PATH, { replace: true });
    }
  }, [isLogged, navigate]);

  const onSubmit = useCallback(
    (data: FieldValues, setError: UseFormSetError<any>) =>
      login(
        {
          email: data.email,
          password: data.password,
        },
        {
          onError: () => {
            setError("root", { message: "There was an unexpected error, please try again" });
          },
          onSuccess({ data }) {
            if (data.error && data.error_msg) {
              setPageError(new Error(data.error_msg));
            }
          },
        },
      ),
    [login],
  );

  const {
    render,
    previous,
    next,
    formState: { errors },
  } = useFormWizard({
    onSubmit,
    defaultValues: {
      email: "",
      password: "",
    },
    schema,
    steps,
  });

  return (
    <Form onSubmit={next}>
      {render({ isLoading, previous })}
      {errors.root && (
        <Typography color="error" variant="body1">
          {errors.root.message}
        </Typography>
      )}
      {pageError && (
        <Typography color="error" variant="body1">
          {pageError.message}
        </Typography>
      )}
    </Form>
  );
};
