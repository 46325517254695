import { Outlet } from "react-router-dom";
import { RequiredAddressModal } from "../contracts/components/RequiredAddressModal";
import { useRequiredAddressModal } from "../contracts/hooks/useRequiredAddressModal";
import { LoadingPage } from "../../shared/pages/LoadingPage";
import { ScrollTopButton } from "@synota-io/synota-shared-ui";

export const ExplorerOutlet = () => {
  const requiredAddressModal = useRequiredAddressModal();

  return (
    <LoadingPage>
      <Outlet />
      <RequiredAddressModal {...requiredAddressModal} />
      <ScrollTopButton />
    </LoadingPage>
  );
};
