const {
  VITE_COMPANY_NAME: COMPANY_NAME,
  VITE_MINER_TERMS_OF_SERVICE: TERMS_OF_SERVICE,
  VITE_SYNOTA_API_URL: SYNOTA_API_URL,
  VITE_CONTRACT_FIELDS_CUSTOM_HACK: CONTRACT_FIELDS_CUSTOM_HACK,
  VITE_SENTRY_ENV: SENTRY_ENV,
  VITE_SENTRY_KEY: SENTRY_KEY,
  VITE_SENTRY_SERVER_NAME: SENTRY_SERVER_NAME,
  VITE_CONSUMER_TOS_URL: CONSUMER_TOS_URL,
  VITE_DASHBOARD_URL: DASHBOARD_URL,
  VITE_API_TIMEOUT_MS: API_TIMEOUT_MS,
} = import.meta.env;

const IS_PRODUCTION = import.meta.env.PROD;
const BITCOIN_NETWORK = import.meta.env.VITE_BITCOIN_NETWORK || "mainnet";
const DEBUG_ENABLED = import.meta.env.VITE_DEBUG === "1";

function getDonationModeData(): { jwt?: string; averageCostPerPatient?: string } {
  try {
    return JSON.parse(import.meta.env.VITE_FEATURE_DATA_DONATION_SHOWCASE_MODE) as {
      jwt?: string;
      averageCostPerPatient?: string;
    };
  } catch (e) {
    return {};
  }
}

// Features
const FEATURE_ENABLE_MAINTENANCE_MODE =
  import.meta.env.VITE_FEATURE_ENABLE_MAINTENANCE_MODE === "1";

const FEATURE_ENABLE_AR_EXPOSURE = import.meta.env.VITE_FEATURE_ENABLE_AR_EXPOSURE === "1";

const FEATURE_ENABLE_DONATION_SHOWCASE_MODE =
  import.meta.env.VITE_FEATURE_ENABLE_DONATION_SHOWCASE_MODE === "1";

const FEATURE_DATA_DONATION_SHOWCASE_MODE = getDonationModeData();

// Debug
if (DEBUG_ENABLED) {
  (window as any)._env = import.meta.env;
}

export {
  IS_PRODUCTION,
  CONSUMER_TOS_URL,
  DASHBOARD_URL,
  BITCOIN_NETWORK,
  COMPANY_NAME,
  SYNOTA_API_URL,
  API_TIMEOUT_MS,
  DEBUG_ENABLED,
  TERMS_OF_SERVICE,
  CONTRACT_FIELDS_CUSTOM_HACK,
  SENTRY_ENV,
  SENTRY_KEY,
  SENTRY_SERVER_NAME,
  FEATURE_ENABLE_MAINTENANCE_MODE,
  FEATURE_ENABLE_AR_EXPOSURE,
  FEATURE_ENABLE_DONATION_SHOWCASE_MODE,
  FEATURE_DATA_DONATION_SHOWCASE_MODE,
};
