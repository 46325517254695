import { FlexLoadingBlock, Page, useAccount, useContracts } from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";

export const LoadingPage = ({ children }: PropsWithChildren) => {
  const { isLoading } = useAccount();
  const { isLoading: isLoadingContracts } = useContracts();

  if (isLoading || isLoadingContracts) {
    return <FlexLoadingBlock variant="logo" pb={18} />;
  }

  return <Page>{children}</Page>;
};
