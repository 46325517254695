import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { Alert, AlertTitle, Typography } from "@mui/material";
import { Done } from "@mui/icons-material";
import {
  Form,
  FormStep,
  FormSubmit,
  InputField,
  SearchField,
  useAccount,
  useContracts,
  useCreateReceiveAddress,
  useDefaultError,
} from "@synota-io/synota-shared-ui";

interface ContractIdFilterValues {
  contractId: { label: string; value: number };
  nameOfAddress: string;
  sourceOfFunds: string;
}

const schema = yup
  .object({
    contractId: yup
      .object({
        label: yup.string().required(),
        value: yup.number().required(),
      })
      .required("You must select a contract"),
    nameOfAddress: yup.string().required("You must set a name for the address"),
    sourceOfFunds: yup.string().required("You must set the source of funds"),
  })
  .required();

export const GenerateAddressForm = () => {
  const { contracts } = useContracts();
  const { jwt, isAdmin } = useAccount();
  const [receiveAddress, setReceiveAddress] = useState<string>("");

  const { control, handleSubmit, formState } = useForm<ContractIdFilterValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      contractId: undefined,
      nameOfAddress: "",
      sourceOfFunds: "Bitcoin Mining",
    },
    mode: "all",
  });

  const { createAddress, isLoading, error } = useCreateReceiveAddress();

  useDefaultError(error, "There was a problem retrieving the address, please try again.");

  const onSubmit = (values: ContractIdFilterValues) => {
    if (!jwt || !values.contractId.value || !values.nameOfAddress) {
      return;
    }
    setReceiveAddress("");
    createAddress(
      {
        contract_id: +values.contractId.value,
        name: values.nameOfAddress,
        jwt,
        source_of_funds: values.sourceOfFunds,
      },
      {
        onSuccess: ({ data }) => {
          setReceiveAddress(data.bitcoin_address);
        },
      },
    );
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6">
        Generate a new onchain address to deposit funds onto node
      </Typography>
      <FormStep>
        <SearchField
          readOnly={!isAdmin}
          options={
            contracts
              ? contracts.map((contract) => ({
                  label: contract.name,
                  value: contract.id,
                }))
              : []
          }
          control={control}
          header="Choose a contract"
          placeholder="Select here"
          name="contractId"
          fullWidth
        />
        <InputField
          readOnly={!isAdmin}
          control={control}
          name="nameOfAddress"
          fullWidth
          header="Name of Address"
        />
      </FormStep>
      <FormSubmit
        isLoading={isLoading}
        endIcon={!isLoading && receiveAddress ? <Done /> : null}
        disabled={!formState.isDirty || !isAdmin}
      >
        Click here to generate
      </FormSubmit>
      {receiveAddress ? (
        <Alert variant="outlined" severity="success">
          <AlertTitle>Here is your receive address</AlertTitle>
          <Typography variant="h6" color="text.primary" gutterBottom>
            {receiveAddress}
          </Typography>
        </Alert>
      ) : null}
    </Form>
  );
};
