import { Box, Stack, Tooltip, Typography, Switch } from "@mui/material";
import {
  AutoEllipsis,
  BadgeTypography,
  ConfirmationModal,
  useAccount,
  useApiFieldErrors,
  useConfirmationModal,
  UserRole,
  useToast,
  useUsersMutations,
} from "@synota-io/synota-shared-ui";
import { UserGroupTableRowProps } from "./UserGroupTable";

const USER_ROLE_COLOR_MAP: Record<UserRole, "success" | "warning"> = {
  admin: "success",
  backup_admin: "success",
  pending_admin: "warning",
  pending_backup_admin: "warning",
  pending_readonly: "warning",
  readonly: "success",
};

export const UserEmailRow = ({ user, onRefetch }: UserGroupTableRowProps) => {
  const toast = useToast();
  const confirmationModal = useConfirmationModal();

  const { jwt, isAdmin, email } = useAccount();
  const { isLoading, enableUser, enableUserResponse, disableUser, disableUserResponse } =
    useUsersMutations();

  useApiFieldErrors(
    enableUserResponse?.data,
    "There was a problem enabling the user, please try again",
  );
  useApiFieldErrors(
    disableUserResponse?.data,
    "There was a problem disabling the user, please try again",
  );

  const switchDisabled =
    user.email === email || user.status === "Pending" || user.role === "admin" || isLoading;

  return isAdmin ? (
    <Stack direction="row" alignItems="center" gap={3}>
      <Tooltip arrow title={user.disabled ? "Enable User" : "Disable User"}>
        <Switch
          size="small"
          disabled={switchDisabled}
          color={user.disabled ? "error" : USER_ROLE_COLOR_MAP[user.role]}
          checked={!user.disabled}
          value={!user.disabled}
          onChange={(_e, checked) => {
            confirmationModal.onOpen({
              title: user.disabled ? `Enable ${user.email}?` : `Disable ${user.email}?`,
              acceptLabel: user.disabled ? "Enable" : "Disable",
              onConfirm() {
                if (checked) {
                  enableUser(
                    {
                      jwt,
                      email_to_enable: user.email,
                    },
                    {
                      onSuccess({ data }) {
                        if (data.success) {
                          onRefetch();
                          confirmationModal.onClose();
                          toast.success(user.email + " was enabled successfully!");
                        }
                      },
                    },
                  );
                } else {
                  disableUser(
                    { jwt, email_to_disable: user.email },
                    {
                      onSuccess({ data }) {
                        if (data.success) {
                          onRefetch();
                          confirmationModal.onClose();
                          toast.success(user.email + " was disabled successfully!");
                        }
                      },
                    },
                  );
                }
              },
            });
          }}
        />
      </Tooltip>
      <Typography variant="subtitle4">
        <AutoEllipsis>{user.email}</AutoEllipsis>
      </Typography>
      <ConfirmationModal isLoading={isLoading} {...confirmationModal} />
    </Stack>
  ) : (
    <Box mt={1} ml={1}>
      <Tooltip arrow placement="left" title={user.disabled ? "Disabled" : user.status}>
        <span>
          <BadgeTypography
            variant="subtitle4"
            status={user.disabled ? "error" : USER_ROLE_COLOR_MAP[user.role]}
          >
            <AutoEllipsis disableTooltip>{user.email}</AutoEllipsis>
          </BadgeTypography>
        </span>
      </Tooltip>
    </Box>
  );
};
