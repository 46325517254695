import { Stack, Alert, AlertTitle, Button, CardProps } from "@mui/material";
import { ContractInfoModal } from "./ContractInfoModal";
import { ChevronRight } from "@mui/icons-material";
import {
  BadgeTypography,
  IContract,
  Link,
  useAccount,
  useContracts,
  useModal,
  useSignContract,
  useToast,
} from "@synota-io/synota-shared-ui";
import { ContractContainerCard } from "./ContractContainerCard";
import { generatePath } from "react-router";
import { CONTRACT_PATH } from "../../../paths";

interface Props extends CardProps {
  contract: IContract;
}

export const PendingContractCard = ({ contract, ...props }: Props) => {
  const link = generatePath(CONTRACT_PATH, {
    contractId: contract.uuid,
  });

  return (
    <ContractContainerCard {...props} contract={contract}>
      <Link sx={{ textDecoration: "none" }} to={link}>
        <BadgeTypography ellipsis maxWidth="24em" status="warning">
          {contract.name}
        </BadgeTypography>
      </Link>

      <Stack direction="row" alignItems="center">
        <Alert severity="success">
          <AlertTitle>Daily Settlement Terms</AlertTitle>
        </Alert>

        <ChevronRight />
      </Stack>

      <OrderingOfSignOffs contract={contract} />
    </ContractContainerCard>
  );
};

const OrderingOfSignOffs = ({ contract }: Props) => {
  if (contract.signedBySelf || !contract.signedByCounterParty) {
    return (
      <>
        <SelfSignOffMarking contract={contract} />
        <CounterPartySignOffMarking contract={contract} />
      </>
    );
  }
  return (
    <>
      <CounterPartySignOffMarking contract={contract} />
      <SelfSignOffMarking contract={contract} />
    </>
  );
};

const CounterPartySignOffMarking = ({ contract }: { contract: IContract }) => {
  if (contract.signedByCounterParty) {
    return (
      <Stack direction="row" alignItems="center">
        <Alert severity="success">
          <AlertTitle>Counterparty Approved</AlertTitle>
        </Alert>
        <ChevronRight />
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center">
      <Alert severity="info">
        <AlertTitle>Pending Counterparty Approval</AlertTitle>
      </Alert>
      <ChevronRight color="disabled" />
    </Stack>
  );
};

const SelfSignOffMarking = ({ contract }: Props) => {
  const { refetch } = useContracts();
  const { signContract, isLoading } = useSignContract();
  const { jwt, isAdmin } = useAccount();
  const { success } = useToast();
  const modal = useModal();
  const selfSignContract = () => {
    if (
      isAdmin &&
      window.confirm(`Are you sure that you want to sign the contract: ${contract.name}`)
    ) {
      signContract(
        {
          shared_uuid: contract.uuid,
          jwt,
        },
        {
          onSuccess: () => {
            refetch();
            modal.onClose();
            success("You have successfully signed the contract.");
          },
        },
      );
    }
  };

  return (
    <>
      {contract.signedBySelf ? (
        <Stack direction="row" alignItems="center">
          <Alert severity="success">
            <AlertTitle>Self Approved</AlertTitle>
          </Alert>
          <ChevronRight />
        </Stack>
      ) : (
        <Stack direction="row" alignItems="center">
          <Alert severity="info">
            <AlertTitle>Pending Self Approval</AlertTitle>
          </Alert>
          {isAdmin ? (
            <Button sx={{ mx: 4 }} variant="contained" color="info" onClick={() => modal.onOpen()}>
              Review to Sign
            </Button>
          ) : null}
          <ChevronRight color="disabled" />
        </Stack>
      )}
      <ContractInfoModal
        isLoading={isLoading}
        onAgree={() => selfSignContract()}
        onDecline={() => modal.onClose()}
        contract={contract}
        {...modal}
      />
    </>
  );
};
