import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Announcement,
  ButtonRow,
  Form,
  FormStep,
  FormSubmit,
  IContract,
  InputField,
  PasswordField,
  useAccount,
  useContracts,
  useForemanAccess,
  useToast,
} from "@synota-io/synota-shared-ui";
import * as yup from "yup";

interface Props {
  contract: IContract;
}

const foremanSchema = yup.object({
  api_key: yup.string().required("API KEY is required!"),
  client_id: yup.string().required("Client ID is required!"),
  pickaxe_id: yup.string().required("Pickaxe ID is required!"),
});

export const ForemanSetupForm = ({ contract }: Props) => {
  const toast = useToast();
  const { jwt, isAdmin } = useAccount();
  const { refetch: refetchContracts } = useContracts();
  const { addAccess, isLoading } = useForemanAccess();

  const { control, handleSubmit, formState } = useForm({
    mode: "all",
    resolver: yupResolver(foremanSchema),
  });

  const onSubmit = handleSubmit(({ api_key, client_id, pickaxe_id }) => {
    addAccess(
      {
        jwt,
        contract_shared_uuid: contract.uuid,
        foreman_credentials: { api_key, client_id, pickaxe_id },
      },
      {
        onSuccess() {
          toast.success("Successfully authorized miner management with Foreman!");
          refetchContracts();
        },
      },
    );
  });

  if (!contract.uuid) {
    return null;
  }

  if (!isAdmin) {
    return <Announcement>Only admin accounts can link miner management accounts.</Announcement>;
  }

  return (
    <Form onSubmit={onSubmit}>
      <FormStep>
        <ButtonRow>
          <PasswordField
            fullWidth
            size="small"
            autoComplete="new-password"
            header="API KEY"
            control={control}
            name="api_key"
          />
        </ButtonRow>
        <ButtonRow>
          <InputField
            size="small"
            autoComplete="off"
            header="Client ID"
            control={control}
            name="client_id"
          />

          <InputField
            size="small"
            autoComplete="off"
            header="Pickaxe ID"
            control={control}
            name="pickaxe_id"
          />
        </ButtonRow>
        <FormSubmit size="small" isLoading={isLoading} disabled={!formState.isValid} fullWidth />
      </FormStep>
    </Form>
  );
};
