import { Box, Card, Slide, Stack, Typography, useTheme } from "@mui/material";
import { PropsWithChildren, ReactNode, useState } from "react";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Label,
  Tooltip as RechartsTooltip,
} from "recharts";
import {
  ChartBackwardsPagination,
  ChartContainer,
  LegendLabel,
  formatDollarAmount,
  formatEnergyAmount,
  useAccount,
  useLayout,
  useSettlementSummary,
} from "@synota-io/synota-shared-ui";
import { getLabel } from "../../../utils/paymentRequests";

type Props = PropsWithChildren<{ contractUuid?: string; height?: number }>;

type UnitType = "usd" | "energy";

export const CashFlowChart = ({ contractUuid, height = 215 }: Props) => {
  const theme = useTheme();
  const { isMobile } = useLayout();
  const { isConsumer } = useAccount();

  const [page, setPage] = useState(0);

  const { getHighestValuedDataKey, summary, isFetching, hasNextPage } = useSettlementSummary({
    prefetch: true,
    contractUuid,
    page,
  });

  const labels = [
    {
      label: "Total Amount Invoiced (USD)",
      color: theme.palette.chart.invoiced,
    },
    {
      label: `${getLabel("energyDelivered", isConsumer)} (MWh)`,
      color: theme.palette.chart.energy,
    },
    {
      label: "Completed Payments (USD)",
      color: theme.palette.chart.revenue,
    },
    {
      label: "Pending Payments (USD)",
      color: theme.palette.chart.pending,
    },
  ];

  return (
    <Slide in direction="up">
      <Box width="100%">
        <ChartContainer title="" labels={labels}>
          <ResponsiveContainer width="100%" height={height}>
            <ComposedChart {...{ overflow: "visible" }} data={summary} barGap={isMobile ? 0 : 4}>
              <XAxis strokeWidth={0} dataKey="formattedDate" />

              <YAxis
                yAxisId="usd"
                style={{ fontSize: "0.8em" }}
                strokeWidth={0}
                dataKey={getHighestValuedDataKey(summary)}
                orientation="left"
                tickFormatter={(value) => formatDollarAmount(value, 0)}
              />

              <YAxis
                yAxisId="energy"
                style={{ fontSize: "0.8em" }}
                strokeWidth={0}
                dataKey="energyDeliveredMwh"
                orientation="right"
              >
                <Label value="MWh" position="top" offset={22} />
              </YAxis>

              <CartesianGrid
                strokeDasharray="5"
                verticalCoordinatesGenerator={() => []}
                stroke={theme.palette.chart.divider}
              />
              <RechartsTooltip
                wrapperStyle={{ zIndex: theme.zIndex.tooltip - 1 }}
                content={<CustomTooltip />}
              />
              <Bar
                yAxisId="usd"
                unit="usd"
                dataKey="invoiceAmount"
                name="Total Amount Invoiced"
                barSize={6}
                fill={theme.palette.chart.invoiced}
              />
              <Bar
                stackId="payment"
                yAxisId="usd"
                dataKey="paymentAmount"
                name="Completed Payments"
                unit="usd"
                barSize={6}
                fill={theme.palette.chart.revenue}
              />
              <Bar
                stackId="payment"
                yAxisId="usd"
                dataKey="pendingAmount"
                name="Pending Payments"
                unit="usd"
                barSize={6}
                fill={theme.palette.chart.pending}
              />
              <Line
                yAxisId="energy"
                unit="energy"
                dataKey="energyDeliveredMwh"
                name={getLabel("energyDelivered", isConsumer)}
                color={theme.palette.chart.energy}
                stroke={theme.palette.chart.energy}
              />
              <Line
                yAxisId="usd"
                unit="usd"
                display="none"
                activeDot={false}
                dataKey="effectiveRateMwh"
                name={getLabel("effectiveRateMwh", isConsumer)}
                color={theme.palette.chart.rate}
                stroke={theme.palette.chart.rate}
              />
              <Line
                yAxisId="usd"
                unit="usd"
                display="none"
                activeDot={false}
                dataKey="calculatedChargeAmount"
                name={getLabel("calculatedChargeAmount", isConsumer)}
                color={theme.palette.chart.calculated}
                stroke={theme.palette.chart.calculated}
              />
            </ComposedChart>
          </ResponsiveContainer>
          <ChartBackwardsPagination
            sx={(theme) => ({ position: "relative", zIndex: theme.zIndex.speedDial })}
            isLoading={isFetching}
            hasPreviousPage={hasNextPage}
            page={page}
            onPageChange={(p) => setPage(p)}
            nextLabel="Next 7 Days"
            previousLabel="Previous 7 Days"
          />
        </ChartContainer>
      </Box>
    </Slide>
  );
};

const axisFormatters = {
  usd: (value: number) => <Typography variant="subtitle1">{formatDollarAmount(value)}</Typography>,
  energy: (value: number) =>
    value === null ? null : (
      <Typography variant="subtitle1">{formatEnergyAmount(value)} MWh</Typography>
    ),
} satisfies Record<UnitType, (v: number) => ReactNode>;

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <Card elevation={5}>
        <Stack py={3} px={8} spacing={3} flexWrap="wrap" flexGrow={1} pl={4}>
          <Typography variant="caption">{label}</Typography>
          {payload.map((axis: any) => (
            <Stack key={axis.name}>
              <LegendLabel label={axis.name} color={axis.color} />
              {axisFormatters[axis.unit as UnitType](axis.value)}
            </Stack>
          ))}
        </Stack>
      </Card>
    );
  }

  return null;
};
