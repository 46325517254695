import { Box, Stack, ButtonProps } from "@mui/material";
import {
  Announcement,
  ButtonRow,
  FormButton,
  IAdditionalCharge,
  Modal,
  UseModalProps,
  useAccount,
  useAdditionalChargesMutations,
  useModal,
} from "@synota-io/synota-shared-ui";
import { ChargeDetails } from "./ChargeDetails";
import { RejectChargeModal } from "./RejectChargeModal";
import { VoidChargeModal } from "./VoidChargeModal";
import { ApproveChargeModal } from "./ApproveChargeModal";

type Props = UseModalProps & {
  charge: IAdditionalCharge | null;
  onSuccess: () => void;
};

const CloseButton = ({ onClose, ...props }: { onClose(): void } & ButtonProps) => (
  <FormButton
    variant="text"
    color="error"
    {...props}
    onClick={() => {
      onClose();
    }}
  >
    Close
  </FormButton>
);

export const ChargeActionsModal = ({ open, onClose, charge, onSuccess }: Props) => {
  const { isConsumer, isSupplier, jwt, isAdmin, refetch } = useAccount();
  const { approveCharge, rejectCharge, voidCharge, isLoading } = useAdditionalChargesMutations();

  const approveModal = useModal();
  const rejectModal = useModal();
  const voidModal = useModal();

  if (!charge) {
    return null;
  }

  const actions =
    isAdmin && !charge.consumerVerified && !charge.void && !charge.rejected ? (
      <Stack px={4} pb={2} flexGrow={1}>
        <ButtonRow>
          {isConsumer ? (
            <>
              <FormButton
                variant="text"
                color="error"
                disabled={isLoading}
                onClick={() => {
                  rejectModal.onOpen();
                }}
              >
                Reject Invoice
              </FormButton>
              <Box flexGrow={1}>
                <FormButton
                  disabled={isLoading}
                  color="secondary"
                  onClick={() => {
                    approveModal.onOpen();
                  }}
                  fullWidth
                >
                  Approve Invoice
                </FormButton>
              </Box>
            </>
          ) : null}
          {isSupplier ? (
            <>
              <CloseButton onClose={onClose} />
              <Box flexGrow={1}>
                <FormButton
                  color="error"
                  disabled={isLoading}
                  onClick={() => {
                    voidModal.onOpen();
                  }}
                  fullWidth
                >
                  Void Invoice
                </FormButton>
              </Box>
            </>
          ) : null}
        </ButtonRow>
        {isConsumer ? (
          <Stack pt={7} pb={3} alignItems="center" justifyContent="center">
            <Announcement maxWidth="90%">
              Approved invoice payments will be attempted frequently and when funds are available.
            </Announcement>
          </Stack>
        ) : null}
      </Stack>
    ) : (
      <CloseButton fullWidth onClose={onClose} />
    );

  const modals = (
    <>
      {isConsumer && approveModal.open ? (
        <ApproveChargeModal
          {...approveModal}
          isLoading={isLoading}
          onConfirm={() => {
            approveCharge(
              { id: charge.id, jwt },
              {
                onSuccess: () => {
                  onSuccess();
                  approveModal.onClose();
                  refetch();
                },
              },
            );
          }}
        />
      ) : null}

      {isConsumer && rejectModal.open ? (
        <RejectChargeModal
          {...rejectModal}
          isLoading={isLoading}
          onConfirm={({ reason }) => {
            rejectCharge(
              { jwt, additional_charge_shared_uuid: charge.uuid, reject_reason: reason },
              {
                onSuccess: () => {
                  onSuccess();
                  rejectModal.onClose();
                  refetch();
                },
              },
            );
          }}
        />
      ) : null}

      {isSupplier && voidModal.open ? (
        <VoidChargeModal
          {...voidModal}
          isLoading={isLoading}
          onConfirm={() => {
            voidCharge(
              { jwt, additional_charge_shared_uuid: charge.uuid },
              {
                onSuccess: () => {
                  onSuccess();
                  voidModal.onClose();
                  refetch();
                },
              },
            );
          }}
        />
      ) : null}
    </>
  );

  return (
    <Modal
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={onClose}
      title="Ad Hoc Invoice"
      actions={actions}
    >
      <ChargeDetails charge={charge} />
      {modals}
    </Modal>
  );
};
