import {
  Card,
  CardContent,
  Slide,
  Stack,
  Table,
  TableBody,
  TableFooter,
  TableHead,
} from "@mui/material";
import { ReactNode, useMemo } from "react";
import {
  AutoEllipsis,
  BodyCell,
  BodyRow,
  ButtonRow,
  DateField,
  Dayjs,
  FormSubmit,
  HeadCell,
  HeadRow,
  ICounterpartyOption,
  IPaymentRequest,
  SearchField,
  StickyTableContainer,
  dayjs,
  formatDate,
  formatDollarAmount,
  formatEnergyAmount,
  getFormattedEnergyEffectiveRate,
  isDayjs,
  useContracts,
  useCounterparties,
  usePaymentRequests,
  useSearchParamsContract,
  useSearchParamsCounterparty,
  useSearchParamsDate,
  useSearchParamsForm,
  useWindowSize,
} from "@synota-io/synota-shared-ui";
import { PaymentRequestLabel, getLabel } from "../../../utils/paymentRequests";

interface SettlementsFilterValues {
  counterparty: ICounterpartyOption | null;
  contract: { label: string; value: string } | null;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

interface SettlementsTableField {
  key: PaymentRequestLabel;
  value: (pr: IPaymentRequest) => ReactNode;
}

interface Props {
  fields: Array<PaymentRequestLabel>;
  canFilter?: boolean;
  startDate?: Dayjs;
  endDate?: Dayjs;
}

export const SettlementsTable = ({ fields, canFilter }: Props) => {
  const { find, contracts, isLoading: isLoadingContracts } = useContracts();

  const searchParamsDate = useSearchParamsDate();

  const { control, watch } = useSearchParamsForm<SettlementsFilterValues>(
    {
      defaultValues: {
        contract: null,
        counterparty: null,
        startDate: dayjs().startOf("month"),
        endDate: dayjs().endOf("day"),
      },
    },
    {
      counterparty: useSearchParamsCounterparty(),
      contract: useSearchParamsContract(),
      endDate: searchParamsDate,
      startDate: searchParamsDate,
    },
  );

  const values = watch();

  // Re-renders ellipsis calculation on window resize
  useWindowSize();

  const input = useMemo(
    () => ({
      include_quotes: true,
      contract_uuid: values.contract?.value,
      counterparty_email: values.counterparty?.value,
      start_time: isDayjs(values.startDate) ? values.startDate.startOf("day").toISOString() : null,
      end_time: isDayjs(values.endDate)
        ? values.endDate.startOf("day").add(1, "day").toISOString()
        : null,
    }),
    [values],
  );

  const { paymentRequests, fetchNextPage, hasNextPage, isFetching } = usePaymentRequests(input);
  const { counterparties } = useCounterparties();

  const tableFields: SettlementsTableField[] = [
    {
      value: (pr) => pr.contractId,
      key: "contractId",
    },
    {
      value: (pr) => <AutoEllipsis>{find(pr.contractId)?.name || "N/A"}</AutoEllipsis>,
      key: "contractName",
    },
    {
      value: (pr) => pr.id,
      key: "invoiceNumber",
    },
    {
      value: (pr) => formatEnergyAmount(pr.energyDeliveredMwh),
      key: "energyDelivered",
    },
    {
      value: (pr) => formatDollarAmount(pr.amountInvoiced),
      key: "invoiceAmount",
    },
    {
      value: (pr) => formatDollarAmount(pr.cashReceived),
      key: "paymentReceived",
    },
    {
      value: (pr) => formatDate(pr.beginTime),
      key: "settlementStart",
    },
    {
      value: (pr) => formatEnergyAmount(pr.energyDeliveredMwh),
      key: "energyDeliveredWithUnit",
    },
    {
      value: (pr) => formatDollarAmount(pr.amountInvoiced),
      key: "hostFeeInvoice",
    },
    {
      value: (pr) => formatDollarAmount(pr.cashReceived),
      key: "customerPayment",
    },
    {
      value: (pr) => getFormattedEnergyEffectiveRate(pr.amountInvoiced, pr.energyDeliveredMwh),
      key: "effectiveRateMwh",
    },
    {
      value: (pr) => pr.taxPayable,
      key: "taxPayable",
    },
  ];

  const visibleFields = tableFields.filter((field) => fields.includes(field.key));

  return (
    <Stack gap={6}>
      {canFilter && (
        <ButtonRow flexWrap="wrap">
          <ButtonRow flexGrow={1}>
            <SearchField
              options={
                contracts
                  ? contracts.map((contract) => ({ label: contract.name, value: contract.uuid }))
                  : []
              }
              control={control}
              name="contract"
              label="Filter by Contract"
              disabled={isLoadingContracts}
              variant="filled"
              fullWidth
            />
            {counterparties.length ? (
              <SearchField
                options={counterparties}
                control={control}
                name="counterparty"
                label="Filter by Counterparty"
                variant="filled"
                fullWidth
              />
            ) : null}
          </ButtonRow>
          <ButtonRow flexGrow={1}>
            <DateField
              variant="filled"
              maxDate={isDayjs(values.endDate) ? values.endDate : undefined}
              control={control}
              name="startDate"
              label="From Date"
              fullWidth
            />
            <DateField
              variant="filled"
              minDate={isDayjs(values.startDate) ? values.startDate : undefined}
              control={control}
              name="endDate"
              label="To Date"
              fullWidth
            />
          </ButtonRow>
        </ButtonRow>
      )}

      <Slide in direction="up">
        <Card>
          <CardContent>
            <StickyTableContainer>
              <Table stickyHeader>
                <TableHead>
                  <HeadRow>
                    {visibleFields.map((field) => (
                      <HeadCell key={field.key}>
                        <AutoEllipsis>{getLabel(field.key)}</AutoEllipsis>
                      </HeadCell>
                    ))}
                  </HeadRow>
                </TableHead>
                <TableBody>
                  {paymentRequests &&
                    paymentRequests.map((pr) => {
                      return (
                        <BodyRow key={pr.id}>
                          {visibleFields.map((field) => (
                            <BodyCell key={pr.contractId + pr.id + field.key}>
                              {field.value(pr)}
                            </BodyCell>
                          ))}
                        </BodyRow>
                      );
                    })}
                </TableBody>
                {!isFetching && !paymentRequests.length ? (
                  <TableFooter>
                    <BodyRow>
                      <BodyCell>No settlements found.</BodyCell>
                    </BodyRow>
                  </TableFooter>
                ) : null}
              </Table>
            </StickyTableContainer>
          </CardContent>
        </Card>
      </Slide>
      <Slide in direction="up">
        <Stack direction="row">
          <FormSubmit
            isLoading={isFetching}
            disabled={!hasNextPage}
            onClick={() => fetchNextPage()}
            variant="contained"
          >
            {isFetching || hasNextPage ? "Load More" : "No more results"}
          </FormSubmit>
        </Stack>
      </Slide>
    </Stack>
  );
};
