import * as Sentry from "@sentry/react";
import { IS_PRODUCTION, SENTRY_ENV, SENTRY_KEY, SENTRY_SERVER_NAME } from "./utils/environment";

const SENTRY_BASE_CONFIG: Sentry.BrowserOptions = {
  dsn: SENTRY_KEY,
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: SENTRY_ENV.toLowerCase() || "development",
};

export const initSentry = () => {
  if (IS_PRODUCTION) {
    Sentry.init(SENTRY_BASE_CONFIG);
    Sentry.setTag("server_name", SENTRY_SERVER_NAME);
  }
};
