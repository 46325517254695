import { Card, CardContent, CardProps, Slide, Stack, Typography } from "@mui/material";
import {
  IContract,
  StatsBox,
  dayjs,
  formatDollarAmount,
  useAccount,
  useStatementData,
} from "@synota-io/synota-shared-ui";
import { PropsWithChildren } from "react";
import { useContractActions } from "../hooks/useContractActions";
import { ContractMoreMenu } from "./ContractMoreMenu";
import { useNavigate } from "react-router-dom";

export type ContractContainerCardProps = PropsWithChildren<
  CardProps & { contract: IContract | null; link?: string }
>;

export const ContractContainerCard = ({
  children,
  contract,
  link,
  ...props
}: ContractContainerCardProps) => {
  const contractActions = useContractActions({ contract });

  const { isSupplier } = useAccount();

  const navigate = useNavigate();
  const defaultInput = {
    startOfMonthTime: dayjs().startOf("month").toISOString(),
    endOfToday: dayjs().endOf("day").toISOString(),
  };

  const { statement } = useStatementData(
    defaultInput.startOfMonthTime,
    defaultInput.endOfToday,
    contract?.uuid,
  );

  if (!contract) {
    return null;
  }

  const outstandingAmount = Number(statement?.currentAmountOutstanding) || 0;

  return (
    <Slide direction="left" in>
      <Card {...props}>
        <CardContent sx={{ minHeight: "6em", display: "flex", alignItems: "center" }}>
          <Stack
            px={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            width="100%"
          >
            <Stack
              flexGrow={1}
              direction="row"
              flexWrap="wrap"
              gap={2}
              rowGap={4}
              pr={4}
              justifyContent="space-between"
              alignItems="center"
            >
              {children}
            </Stack>
            <Stack direction="row" alignItems="center" alignSelf="start">
              {link ? (
                <StatsBox
                  sx={{ whiteSpace: "nowrap" }}
                  onClick={() => navigate(link)}
                  variant={outstandingAmount > 0 ? "primary" : "neutral"}
                  fontSize="small"
                  title={`Current ${isSupplier ? "A/R" : "A/P"}`}
                  items={[
                    <Typography variant="inherit" key="usd">
                      {formatDollarAmount(outstandingAmount)}
                    </Typography>,
                  ]}
                />
              ) : null}
              {contractActions.length ? (
                <ContractMoreMenu contract={contract} actions={contractActions} />
              ) : null}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Slide>
  );
};
