import { Navigate, Outlet, useParams } from "react-router-dom";
import { Breadcrumbs } from "./components/Breadcrumbs";
import { useContracts } from "@synota-io/synota-shared-ui";
import { RequiredAddressModal } from "./components/RequiredAddressModal";
import { useRequiredAddressModal } from "./hooks/useRequiredAddressModal";
import { CONTRACTS_PATH } from "../../paths";
import { LoadingPage } from "../../shared/pages/LoadingPage";

export const ContractsOutlet = () => {
  const requiredAddressModal = useRequiredAddressModal();

  const { contractId } = useParams();
  const { contract, isLoading } = useContracts(contractId);

  if (contractId && !isLoading && !contract) {
    return <Navigate to={CONTRACTS_PATH} />;
  }

  return (
    <LoadingPage>
      <Breadcrumbs />
      <Outlet />
      <RequiredAddressModal {...requiredAddressModal} />
    </LoadingPage>
  );
};
