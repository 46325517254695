import {
  FormStep,
  FormSubmit,
  InputField,
  UseFormWizardStepProps,
} from "@synota-io/synota-shared-ui";

interface Props extends UseFormWizardStepProps {}

export const Email = ({ control }: Props) => {
  return (
    <FormStep>
      <InputField autoFocus control={control} type="email" name="email" header="Email" fullWidth />
      <FormSubmit disabled={control.getFieldState("email").invalid} />
    </FormStep>
  );
};
