import { Box, Button, Card, Grid, Slide, Stack } from "@mui/material";
import { DetailsList, DetailsListItem, IContract, useAccount } from "@synota-io/synota-shared-ui";
import { useContractCustomFields } from "../hooks/useContractCustomFields";
import { ContractMoreMenu } from "./ContractMoreMenu";
import { ReserveAmountHeld } from "./ReserveAmountHeld";
import { useContractActions } from "../hooks/useContractActions";
import { ExpandCircleDown, ExpandLess } from "@mui/icons-material";
import { useRef, useState } from "react";

interface Props {
  contract: IContract | null;
}

export const ContractDetails = ({ contract }: Props) => {
  const { hasLightningNode, isConsumer } = useAccount();

  const [collapsed, setCollapsed] = useState(true);

  const contractFields = useContractCustomFields(contract);
  const contractActions = useContractActions({ contract });

  const ref = useRef<HTMLDivElement>(null);

  const toggleDetails = () => setCollapsed((curr) => !curr);

  if (!contract) {
    return null;
  }

  return (
    <Card>
      <Stack alignItems="center" direction="row" pr={2}>
        <Button
          size="large"
          onClick={toggleDetails}
          endIcon={collapsed ? <ExpandCircleDown /> : <ExpandLess />}
        >
          Daily Settlement Terms
        </Button>
        <Box
          flexGrow={1}
          height="2em"
          role="button"
          onClick={toggleDetails}
          sx={{ cursor: "pointer" }}
        />
        <ContractMoreMenu contract={contract} actions={contractActions} />
      </Stack>
      <Box
        ref={ref}
        sx={{
          overflow: "hidden",
        }}
      >
        <Slide container={ref.current} mountOnEnter unmountOnExit in={!collapsed} direction="down">
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <DetailsList>
                {contractFields
                  .filter((field) => !!field.value)
                  .map((field) => {
                    return (
                      <DetailsListItem key={field.label} title={field.label}>
                        {field.value}
                      </DetailsListItem>
                    );
                  })}
                {contract.customDescriptions.length ? null : (
                  <>
                    {isConsumer && hasLightningNode && (
                      <DetailsListItem title="Deposit Address">
                        {contract?.lastDepositAddress}
                      </DetailsListItem>
                    )}
                    <ReserveAmountHeld contract={contract} />
                  </>
                )}
              </DetailsList>
            </Grid>
          </Grid>
        </Slide>
      </Box>
    </Card>
  );
};
