import {
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableRow,
  Typography,
} from "@mui/material";

import { StrikeFeatureForm } from "./StrikeFeatureForm";
import {
  Announcement,
  Link,
  PaymentTimingConfiguration,
  useAccount,
} from "@synota-io/synota-shared-ui";

export const ConsumerPaymentMethodForm = () => {
  const { paymentMethod, paymentTimingConfiguration } = useAccount();

  if (!paymentMethod) {
    return (
      <Box p={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Stack spacing={6}>
      <Typography variant="h6">Payment Method</Typography>
      {paymentMethod.isStrike ? <StrikeFeatureForm /> : null}
      {paymentMethod.isBluePenguin ? (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Routing Number</TableCell>
                <TableCell>{paymentMethod.details?.BluePenguin?.routing_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Account Number</TableCell>
                <TableCell>{paymentMethod.details?.BluePenguin?.account_number}</TableCell>
              </TableRow>
              {paymentTimingConfiguration ? (
                <TableRow>
                  <TableCell>Payment Schedule</TableCell>
                  <TableCell>
                    <PaymentTiming config={paymentTimingConfiguration} />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TableCell colSpan={2}>
                  <Stack direction="row" pt={4}>
                    <Announcement>
                      To update banking information, please email{" "}
                      <Link target="_blank" color="inherit" href="mailto:support@synota.io">
                        support@synota.io
                      </Link>{" "}
                      to receive an ACH Account Authorization form.
                    </Announcement>
                  </Stack>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      ) : null}
    </Stack>
  );
};

const DAY_OF_WEEK_MAP: Record<string, string> = {
  Sun: "Sundays",
  Mon: "Mondays",
  Tue: "Tuesdays",
  Wed: "Wednesdays",
  Thu: "Thursdays",
  Fri: "Fridays",
  Sat: "Saturdays",
};

const PaymentTiming = ({ config }: { config: PaymentTimingConfiguration }) => {
  if (config.Weekly) {
    const day = DAY_OF_WEEK_MAP[config.Weekly.day_of_week];
    if (day) {
      return `Weekly on ${day}`;
    }
  }

  if (config.Immediately) {
    return "Immediate";
  }

  return "N/A";
};
