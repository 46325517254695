import { Stack, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { CashFlowChart } from "../../dashboard/components/CashFlowChart";
import { MetricsTable } from "../../dashboard/components/MetricsTable";
import { ContractDetails } from "../components/ContractDetails";
import { ContractMonthlySummary } from "../reports/ContractMonthlySummary";
import {
  REPORT_ENDPOINTS,
  useAccount,
  useBreakpoint,
  useContracts,
} from "@synota-io/synota-shared-ui";
import { ContractReportDownloader } from "../reports/ContractReportDownloader";
import { MinerManagement } from "../miner-management/MinerManagement";

export const ContractPage = () => {
  const { isConsumer, isAdmin, isReady } = useAccount();
  const { contractId } = useParams();
  const { contract } = useContracts(contractId);
  const isDesktop = useBreakpoint("lg");

  const { foremanAccess, consumerApprovedSharing } = contract?.minerManagement || {};

  const minerManagementReady = isReady && isAdmin && contract;
  const supplierMinerManagementReady = isConsumer || (foremanAccess && consumerApprovedSharing);

  const showMinerManagement = Boolean(minerManagementReady && supplierMinerManagementReady);

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} lg={6}>
        {isDesktop ? (
          <CashFlowChart
            contractUuid={contract?.uuid}
            height={showMinerManagement ? 324 : undefined}
          />
        ) : (
          <CashFlowChart contractUuid={contract?.uuid} />
        )}
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack gap={6} height={showMinerManagement ? "100%" : undefined}>
          <MetricsTable contract={contract} />
          {contract && showMinerManagement ? <MinerManagement contract={contract} /> : null}
        </Stack>
      </Grid>
      <Grid item xs={12} xl={contract?.hasAuditReport ? 5 : 12}>
        <ContractMonthlySummary contract={contract} />
      </Grid>
      {contract?.hasAuditReport ? (
        <Grid item xs={12} xl={7}>
          <ContractReportDownloader report={REPORT_ENDPOINTS.AUDIT} contract={contract} />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <ContractDetails contract={contract} />
      </Grid>
    </Grid>
  );
};
