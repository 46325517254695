import { CONTRACTS_PATH, CONTRACT_EXPOSURE_PATH, CONTRACT_PATH } from "../../../paths";
import { BreadLinkProps } from "../components/Breadcrumbs";
import { useParams } from "react-router-dom";
import { useContracts } from "@synota-io/synota-shared-ui";

export const useBreadcrumbs = () => {
  const { contractId } = useParams();
  const { contract } = useContracts(contractId);

  const links: BreadLinkProps[] = [];

  links.push({
    title: "Contracts",
    path: CONTRACTS_PATH,
  });

  if (contract) {
    links.push(
      {
        title: contract.name,
        path: CONTRACT_PATH,
        params: {
          contractId: contract.uuid,
        },
      },
      {
        title: "Exposure",
        path: CONTRACT_EXPOSURE_PATH,
        params: {
          contractId: contract.uuid,
        },
      },
    );
  }

  return { links };
};
