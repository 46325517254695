import { Layout } from "@synota-io/synota-shared-ui";
import { useIsFetching } from "@tanstack/react-query";
import { SidebarContent } from "./SidebarContent";
import { TopbarContent } from "./TopbarContent";

export const LayoutContainer = () => {
  const isFetching = useIsFetching();
  return (
    <Layout
      isFetching={Boolean(isFetching)}
      sidebar={<SidebarContent />}
      topbar={<TopbarContent />}
    />
  );
};
